<template>
  <div id="sales-reports-view" class="sales-reports-container">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="SalesReports">
      <template v-slot:right-end>
        <vs-button v-if="sales.length > 0 && !errorFetching" class="mt-3 py-4 px-3 mr-4" color="primary"
                   @click="performDownloadSales(false)">{{ $t('DownloadCSV') }}</vs-button>
        <vs-button v-if="sales.length > 0 && !errorFetching" class="mt-3 py-4 px-3" color="primary"
                   @click="performDownloadSales(true)">{{ $t('DownloadExcel') }}</vs-button>
        <vs-button @click="viewAllReportData()"
                   class="mt-3 py-4 px-3 ml-4" color="primary">
          {{ viewAll ? $t('ShowLess') : $t('ViewAll') }}
        </vs-button>
      </template>
    </breadcrumb-base>


    <transition name="fade">
      <div class="vx-row">
        <div class="vx-col md:w-1/5 4">
          <vx-card>
            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{$t('ClearAllFilters').toUpperCase()}}
            </vs-button>
            <div class="py-2"></div>
            <!--            From TO Date Filters -->
            <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('FromDate')"
                        :disabled-dates="{from: new Date()}"
                        v-model="startDate" v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('ToDate')" v-model="endDate" :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>
            <div class="vs-col mb-4 w-full" v-if="$localStorage.get('irole') == 0 && users.length > 0">
              <label style="font-size: 12px; font-weight: bold">{{ $t('SelectUser') }}
                <span
                  @click="selectedUser = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class="pr-4"
                v-model="selectedUser"
                v-if="users.length > 0"
                @input="onFilterChanged"
              >
                <vs-select-item key="-1" :value="-1" :text="$t('All')" />
                <template v-for="(user,index) in users">
                  <vs-select-item :key="index" :value="user.user_id" :text="user.username" />
                </template>
              </vs-select>
            </div>

            <div class="vs-col w-full"
                 v-if="productsFilter.length > 0">
              <label style="font-size: 12px; font-weight: bold">{{ $t('Product') }}
                <span
                  @click="selectedProduct = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class="pr-4 w-full"
                v-model="selectedProduct"
                v-if="productsFilter.length > 0"
                @input="onFilterChanged"
              >

                <vs-select-item :key="index" :value="product"
                                :text="product.product_name" v-for="(product,index) in productsFilter"/>
              </vs-select>
              <div class="py-2"></div>
            </div>
            <div class="vs-col mb-4 w-full"
                 v-if="productsFilter.length > 0 && selectedProduct">
              <label style="font-size: 12px; font-weight: bold">{{ $t('Denomination') }}
                <span
                  @click="selectedProductConfigId = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class="pr-4 w-full"
                v-model="selectedProductConfigId"
                v-if="productsFilter.length > 0"
                @input="onFilterChanged"
              >
                <template v-for="(product) in selectedProduct.configurations">

                  <vs-select-item
                    :value="product.product_configuration_id + 'product_configuration_id'"
                    :text="product.product_configuration_value | germanNumberFormat"/>
                </template>
              </vs-select>
            </div>

            <div class="py-2"></div>
            <div class="vs-col mb-4 w-full" v-for="(item, index) in Object.keys(salesFilters)">
              <label style="font-size: 12px; font-weight: bold">{{ $t(preProcessFilters(item)) }}
                <span
                  v-if="filterSelectData[item] != null"
                  @click="clearFilter(item)"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
<!--              <vs-row class="vs-row">-->
<!--                <h4>{{ $t(preProcessFilters(item)) }}</h4>-->
<!--                <vs-button class="ml-2" v-if="filterSelectData[item] != null" color="warning"-->
<!--                           type="flat" icon-pack="feather" icon="icon-x" size="small"-->
<!--                           @click="clearFilter(item)">{{ $t('Clear') }}-->
<!--                </vs-button>-->
<!--              </vs-row>-->
              <ul class="centerx mt-4">
                <li class="my-2" v-for="(option, index) in salesFilters[item]">
                  <vs-radio v-model="filterSelectData[item]" v-on:change="filterChanged"
                            :vs-value="option.id.toString() + item">{{ $t(preProcessFilters(option.name))}}
                  </vs-radio>
                </li>
              </ul>
            </div>


            <vs-button  v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{$t('Filter').toUpperCase()}}
            </vs-button>
          </vx-card>
        </div>
        <div class="vs-col md:w-4/5">

          <!--    Error List State-->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                     class="mx-auto mb-4 max-w-full">
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('ErrorData')}}</h1>
              </div>
            </div>
          </transition>
          <!--    Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="sales.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="sales.length > 0 && !errorFetching" ref="table"
                             :max-items="20" search
                             :data="sales"
          >
            <!--                             v-on:selected="openProductDetailPage"-->
            <template slot="header">
              <table class="header-table" style="width: auto;" v-if="salesRaw">
                <thead>
                <th class="text-center">
                  {{ $t('TotalSaleAmount') }}
                </th>
                <th class="text-center">
                  {{ $t('TotalBuyingAmount') }}
                </th>
                <th class="text-center">
                  {{ $t('TotalSaleProfit') }}
                </th>
                <th  v-if="salesCounters.has_face_value" class="text-center">
                  {{ $t('ForecastProfit') }}
                </th>
                <th class="text-center">
                  {{ $t('Quantity') }}
                </th>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">
                    <span>{{salesCounters.total_sale_amount | germanNumberFormat4}}</span>
                  </td>
                  <td class="text-center">
                    <span>{{salesCounters.total_buying_amount | germanNumberFormat4}} </span>
                  </td>
                  <td class="text-center">
                    <span>{{salesCounters.total_profit | germanNumberFormat4}}</span>
                  </td>
                  <td  v-if="salesCounters.has_face_value" class="text-center">
                    <span>{{salesCounters.face_value_profit | germanNumberFormat4}} </span>
                  </td>

                  <td class="text-center">
                    <span>{{salesCounters.total_quantity}}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>

            <template slot="thead">


              <vs-th >
                <div class="">
                  <p>{{ $t('SoldDate') }}</p>
                  <p>{{ $t('IPAddress') }}</p>
                </div>
              </vs-th>
              <vs-th >{{$t('Product')}}</vs-th>
              <vs-th >{{$t('Quantity')}}</vs-th>
              <vs-th class="" >{{$t('BuyingPrice')}}</vs-th>
              <vs-th class="" >{{$t('SellingPrice')}}</vs-th>
              <vs-th >{{$t('SellerProfit')}}</vs-th>
              <vs-th >{{$t('User')}}</vs-th>
              <vs-th>{{$t('Actions')}}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div class="">
                    <p class="product-name font-medium truncate" >{{ tr.created | dateFormat }}</p>
                    <p class="product-name"
                       style="font-size: 12px;"
                    >{{ tr.ip_address }}</p>
                  </div>
                </vs-td>
                <vs-td>
                  <p class="product-name">{{ tr.product | title }} <span v-if="tr.value != null"> - {{ tr.value | germanNumberFormat }}</span></p>
                </vs-td>
                <vs-td>
                  <p class="product-name">{{ tr.quantity }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name" >{{ tr.buying_price | germanNumberFormat4 }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name" >{{ tr.total_amount| germanNumberFormat4 }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name">{{ tr.seller_profit | germanNumberFormat4 }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name">{{ tr.user_name }}</p>
                </vs-td>
                <vs-td v-if="tr.is_bulk_transaction">
<!--                  <vs-button-->
<!--                    color="danger"-->
<!--                    @click="reclaimPins(tr)" >{{ $t('Reclaim') }}</vs-button>-->
                  <a
                    v-if="tr.bulk_transaction_csv_file"
                    :href="tr.bulk_transaction_csv_file" download>
                    <vs-button
                      class="ml-2">{{$t('DownloadTXT') }}</vs-button>
                  </a>
                </vs-td>
                <vs-td v-else>
                  <vs-button
                    v-if="tr.is_reclamation"
                    color="danger"
                    @click="reclaimPins(tr)" >{{ $t('Reclaim') }}</vs-button>
                  <vs-button
                    class="ml-2"
                    @click="printReport(tr)" >{{ showDownloadText(tr) ? $t('DownloadTXT') : $t('Print') }}</vs-button>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>

    <vx-modal v-if="showPrintModal" component-class="modal-container-fit">
      <template slot="body">
        <div v-if="purchaseData">
          <div style="max-height: 500px; overflow-y: auto;">
            <div class="text-center status-card" >
              <div v-if="purchaseData">

                <receipt-print
                  v-on:close="closeModal()"
                  :is-duplicate="true"
                  :print-report-type="printReportType"
                  :purchase-data="purchaseData" ></receipt-print>
              </div>
            </div>

          </div>

        </div>
      </template>
    </vx-modal>
    <vx-modal v-if="showReclaimPinsModal" component-class="modal-container-fit">
      <template slot="body">
        <div v-if="purchaseData">
          <div style="min-height: 200px; min-width: 300px;">
            <div class="text-center status-card" >
              <div v-if="purchaseData">

                <reclaim-pins
                  v-on:close="closeModal()"
                  :print-report-type="printReportType"
                  :purchase-data="purchaseData" ></reclaim-pins>
              </div>
            </div>

          </div>

        </div>
      </template>
    </vx-modal>


    <vx-modal v-if="showDownloadEmailModal" component-class="modal-container-fit">
      <template slot="body">
        <div style="min-width: 300px; overflow-y: auto;">
          <div class="text-center status-card" >
            <div class="flex flex-col py-4 space-y-4">
              <h1>
                {{ $t('GetSalesReportToEmail') }}
              </h1>
              <div class="">

                <vs-input class="p-3 w-full"
                          :placeholder="$t('EmailId')" v-model="downloadEmail"/>
              </div>
              <div class="flex flex-row">
                <vs-button
                  class="ml-2"
                  @click="onCloseDownloadEmailModal" color="danger">
                  {{ $t('Cancel') }}
                </vs-button>
                <vs-button
                  class="ml-2"
                  @click="onCloseDownloadEmailModal(downloadEmail)" >{{ $t('Download') }}</vs-button>
              </div>
            </div>
          </div>

        </div>
      </template>
    </vx-modal>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'
import ReclaimPins from "@/views/components/ReclaimPins";

export default {
  name: 'SalesReportsView',
  components: {
    ReclaimPins,
    ReceiptPrint,
    Datepicker,
    VxModal,
  },
  data() {
    return {
      isExcel: false,
      downloadEmail: "",
      showDownloadEmailModal: false,
      pinDownload: false,
      selectedUser: -1,
      showPrintModal: false,
      showReclaimPinsModal: false,
      purchaseData: null,
      printReportType: null,
      cPage: 1,
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Sales Reports', i18n: 'SalesReports', active: true },
      ],
      dynamicType: ['Soft API', 'EPay', 'International Top-Up', 'Direct Recharge', 'Gift card', 'FlixBus', 'Content Card PIN'],
      filterSelectData: {},
      productsFilter: {},
      selectedProduct: null,
      selectedCustomer: null,
      selectedProductConfigId: null,
      startDate: null,
      endDate: null,
      customerFilter: [],
      viewAll: false,
    }
  },
  computed: {
    downloadCsv() {

    },
    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),}
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    vPage() {
      return this.$store.state.reports.vPage
    },
    vTotalPages() {
      return this.$store.state.reports.vTotalPages
    },
    sales() {
      return this.$store.state.reports.sales
    },
    salesCounters() {
      return this.$store.state.reports.salesCounters
    },
    salesRaw() {
      return this.$store.state.reports.salesRaw
    },
    salesFilters() {
      return this.$store.state.reports.typeOfFilters || {}
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.sales.length
    },
    users() {
      return this.$store.state.user.users
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    onOpenDownloadEmailModal(isExcel = false) {
      this.showDownloadEmailModal = true;
      this.isExcel = isExcel
    },
    onCloseDownloadEmailModal(email) {
      if(email) {
        this.performDownloadSales(this.isExcel);
      }
      this.showDownloadEmailModal = false;
    },
    showDownloadText(d) {
      let pType = -1
      const data = d;
      if(typeof(data.json_data) == "string") {
        data.json_data = JSON.parse(data.json_data)
      }

      // GiftCards
      if (data.inventory_type === 0 && data.dynamic_type == 4) {

      } else if (data.inventory_type === 0) {
        pType = 0
      }
      // CashCards
      if (data.inventory_type === 1 && (data.dynamic_type == 0 || data.dynamic_type == 9 )) {
        pType = 0
      }

      if (data.inventory_type === 1 && (data.dynamic_type == 1 || data.dynamic_type == 10)) {
        pType = 0
      }

      //Calling Cards
      if (data.inventory_type === 3) {
        pType = 0
      }

      return this.pinDownload && pType === 0;
    },
    preProcessFilters(value) {
      if (!value) return ''
      value = value.replace('-', ' ')
      value = value.replace('_', ' ')
      value = value.toString()
      const arr = value.split(' ')
      const capitalized_array = []
      arr.forEach((word) => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
        capitalized_array.push(capitalized)
      })
      value = capitalized_array.join(' ')
      let v = value.replace(' ', '')
      v = v.replace('-', ' ')
      v = v.replace('_', ' ')
      return v;
    },
    closeModal() {
      this.purchaseData = false
      this.showPrintModal = false
      this.showReclaimPinsModal = false
      this.fetchSales(this.cPage - 1, this.getFilters())
    },
    reclaimPins(d) {
      const data = d;
      if(typeof(data.json_data) == "string") {
        data.json_data = JSON.parse(data.json_data)
      }
      // Direct Recharge
      if (data.inventory_type === 1 && (data.dynamic_type == 3 || data.dynamic_type == 8)) {
        this.printReportType = 3
        this.purchaseData = data.json_data.transaction_details
      }

      // GiftCards
      if (data.inventory_type === 0 && data.dynamic_type == 4) {
        data.json_data.product_name = data.product
        data.json_data.value = data.value
        this.purchaseData = data.json_data
        this.printReportType = 4
      } else if (data.inventory_type === 0) {
        this.printReportType = 0
        this.purchaseData = data.json_data
      }
      // CashCards
      if (data.inventory_type === 1 && (data.dynamic_type == 0 || data.dynamic_type == 9 )) {
        this.printReportType = 0
        if(data.json_data['type'] != undefined) {
          this.purchaseData = data.json_data
        } else {
          this.purchaseData = data
        }
        if(data.is_bulk_transaction) {
          this.purchaseData = data
        }
      }

      if (data.inventory_type === 1 && ( data.dynamic_type == 1 || data.dynamic_type == 10)) {
        this.printReportType = 0
        this.purchaseData = data.json_data
        if(data.is_bulk_transaction) {
          this.purchaseData = data
        }
      }

      //Calling Cards
      if (data.inventory_type === 3) {
        this.printReportType = 0
        this.purchaseData = data.json_data
      }

      // GiftCards
      if (data.inventory_type === 1 && data.dynamic_type == 4) {
        data.json_data.product_name = data.product
        data.json_data.value = data.value
        this.purchaseData = data.json_data
        this.printReportType = 4
      }
      // International TopUp
      if (data.inventory_type === 1 && (data.dynamic_type == 2 || data.dynamic_type == 7)) {
        this.printReportType = 2
        this.purchaseData = data.json_data
        this.purchaseData.created = data.created
      }
      // FlixBus
      if (data.inventory_type === 1 && data.dynamic_type == 5) {
        this.printReportType = 5
        this.purchaseData = data.json_data
      }
      this.purchaseData.pins_and_batches = data.pins_and_batches;
      this.showReclaimPinsModal = true

    },
    printReport(d) {
      const data = d;
      if(typeof(data.json_data) == "string") {
        data.json_data = JSON.parse(data.json_data)
      }
      // Direct Recharge
      if (data.inventory_type === 1 && (data.dynamic_type == 3 || data.dynamic_type == 8)) {
        this.printReportType = 3
        this.purchaseData = data.json_data.transaction_details
        this.purchaseData.created = data.created;
      }

      // GiftCards
      if (data.inventory_type === 0 && data.dynamic_type == 4) {
        data.json_data.product_name = data.product
        data.json_data.value = data.value
        this.purchaseData = data.json_data
        this.printReportType = 4
      } else if (data.inventory_type === 0) {
        this.printReportType = 0
        this.purchaseData = data.json_data
      }
      // CashCards
      if (data.inventory_type === 1 && (data.dynamic_type == 0 || data.dynamic_type == 9 )) {
        this.printReportType = 0
        if(data.json_data['type'] != undefined) {
          this.purchaseData = data.json_data
        } else {
          let pins = data.pins_and_batches
          let transaction_pins = [];
          delete data['pins_and_batches']
          delete data['json_data']
          for(let i = 0; i < pins.length; i++) {
            transaction_pins.push({
              ...pins[i],
              serial: pins[i].batch,
              ...data,
            })
          }
          this.purchaseData = {
            ...data,
            transaction_pins: transaction_pins,
          }
        }
      }

      if (data.inventory_type === 1 && ( data.dynamic_type == 1 || data.dynamic_type == 10)) {
        this.printReportType = 0
        if(data.json_data['type'] != undefined) {
          this.purchaseData = data.json_data
        } else {
          let pins = data.pins_and_batches
          let transaction_pins = [];
          delete data['pins_and_batches']
          delete data['json_data']
          for(let i = 0; i < pins.length; i++) {
            transaction_pins.push({
              ...pins[i],
              serial: pins[i].batch,
              ...data,
            })
          }
          this.purchaseData = {
            ...data,
            transaction_pins: transaction_pins,
          }
        }
      }

      //Calling Cards
      if (data.inventory_type === 3) {
        this.printReportType = 0
        this.purchaseData = data.json_data
      }

      // GiftCards
      if (data.inventory_type === 1 && data.dynamic_type == 4) {
        data.json_data.product_name = data.product
        data.json_data.value = data.value
        this.purchaseData = data.json_data
        this.printReportType = 4
      }

      // International TopUP
      if (data.inventory_type === 1 && (data.dynamic_type == 2 || data.dynamic_type == 7)) {
        this.printReportType = 2
        this.purchaseData = data.json_data
        this.purchaseData.created = data.created
        console.log(this.purchaseData)
      }
      // FlixBus
      if (data.inventory_type === 1 && data.dynamic_type == 5) {
        this.printReportType = 5
        this.purchaseData = data.json_data
      }
      this.showPrintModal = true
    },
    performDownloadSales(isExcel = false) {
      this.downloadSales(false, this.getFilters(), isExcel)
    },
    viewAllReportData() {
      this.viewAll = !this.viewAll;

      this.filterChanged()
    },
    onCustomerFilterChanged() {
      this.filterChanged()
    },
    onCustomerTextChanged(value) {
      console.log(value)
      const search_text = value.srcElement.value
      const filter = {
        search_text,
      }
      this.$vs.loading()
      return this.$store.dispatch('reports/searchCustomers', { filter })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if (data.message_type == 1) {
            this.customerFilter = data.customers
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: error.response.data.message || error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    onFilterChanged() {
      this.filterChanged()
    },
    clearFilterData() {
      this.filterSelectData = {}
      this.selectedCustomer = null
      this.startDate = null
      this.endDate = null
      this.selectedProduct = null
      this.selectedProductConfigId = null
      this.filterChanged()
    },
    clearFilter(key) {
      this.filterSelectData[key] = null
      this.filterChanged()
    },
    getFilters() {
      const filter = {}
      Object.keys(this.filterSelectData).forEach((key) => {
        if (this.filterSelectData[key] != null) {
          filter[key] = this.filterSelectData[key].replace(key, '')
        }
      })
      if (this.startDate) {
        filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
      }

      if (this.selectedProduct) {
        filter.product_id = this.selectedProduct.product_id
      }
      if (this.selectedProductConfigId) {
        filter.product_configuration_id = this.selectedProductConfigId.replace('product_configuration_id', '')
      }
      if (this.selectedCustomer) {
        filter.organisation_id = this.selectedCustomer.replace('organisation_id', '')
      }
      if (this.selectedUser != -1) {
        filter.user_id = this.selectedUser
      }

      if(this.viewAll) {
        filter['view_all'] = this.viewAll ? 1 : 0;
      }
      return filter
    },
    filterChanged() {
      this.fetchSales('0', this.getFilters())
    },
    listPageChanged(item) {
      this.fetchSales(this.cPage - 1, this.getFilters())
    },
    fetchSales(page = '0', filter = {}, isDownload = false) {
      this.fetchSalesCounter(page, filter);
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchSalesReports', { page, filter })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    fetchSalesCounter(page = '0', filter = {}) {
      return this.$store.dispatch('reports/fetchSalesReportCounters', {page, filter})
        .then(() => {
          this.errorFetching = false
        })
        .catch((error) => {
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    downloadSales(isMore = false, filter = {}, isExcel = false) {
      this.$vs.loading()
      filter.download = 1
      if (isExcel) {
        filter.download_type = 'excel'
      }
      return this.$store.dispatch('reports/fetchDownloadSalesReports', { isMore, filter })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          try {
            if(data.download_id) {
              this.$vs.notify({
                position: 'top-center',
                title: 'Success',
                text: data.message,
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
              })
            } else {
              if(isExcel) {
                const hiddenElement = document.createElement('a')
                hiddenElement.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
                hiddenElement.target = '_blank'
                hiddenElement.download = 'sales_report.xlsx';
                hiddenElement.click()
              } else {
                const hiddenElement = document.createElement('a')
                hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
                hiddenElement.target = '_blank'
                hiddenElement.download = 'sales_report.txt';
                hiddenElement.click()
              }
            }
          } catch (e) {
            if(isExcel) {
              const hiddenElement = document.createElement('a')
              hiddenElement.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
              hiddenElement.target = '_blank'
              hiddenElement.download = 'sales_report.xlsx';
              hiddenElement.click()
            } else {
              const hiddenElement = document.createElement('a')
              hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
              hiddenElement.target = '_blank'
              hiddenElement.download = 'sales_report.txt';
              hiddenElement.click()
            }
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadUsers(isMore = false) {
      this.$vs.loading()
      this.$store.dispatch('user/fetchSellerUsers', { isMore })
        .then((data) => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: err.response.data.message || err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchProductsFilter() {
      this.$store.dispatch('reports/fetchProductFilters').then((data) => {
        if (data.message_type === 1) {
          try {
            this.productsFilter = data.type_of_filters.products
          } catch (e) {
            this.productsFilter = []
          }
        }
      })
    }
  },
  created() {
    this.$store.commit('reports/RESET')
    this.endDate = new Date()
    this.startDate = new Date()
    // this.endDate = moment(new Date()).add(1, 'days').toDate()
    this.fetchSales('0', this.getFilters())
    this.fetchProductsFilter();
    if (localStorage.getItem('irole') == 0) this.loadUsers()
  },
  mounted() {
    if (localStorage.getItem('merge_receipts') == null) {
      localStorage.setItem('merge_receipts', 'false')
    }
    this.mergeReceipts = localStorage.getItem('merge_receipts') == 'true'
    this.pinDownload = localStorage.getItem('pin_download') == 'true'
  },
}
</script>

<style lang="scss">

#sales-reports-view {
  .vs-select--label {
    padding: 0;
  }

  .header-table {
    width: auto;

    th {
      font-weight: normal;
      font-size: 14px;
      min-width: 200px;
    }
    td {
      font-weight: bold;
      font-size: 24px;
      color: rgba(var(--vs-primary), 1);
    }
  }

.vs-con-table {

/*
  Below media-queries is fix for responsiveness of action buttons
  Note: If you change action buttons or layout of this page, Please remove below style
*/
@media (max-width: 689px) {
  .vs-table--search {
    margin-left: 0;
    max-width: unset;
    width: 100%;

  .vs-table--search-input {
    width: 100%;
  }
}
}

@media (max-width: 461px) {
  .items-per-page-handler {
    display: none;
  }
}

@media (max-width: 341px) {
  .products-list-btn-container {
    width: 100%;

  .dd-actions,
  .btn-add-new {
    width: 100%;
    margin-right: 0 !important;
  }
}
}

.product-name {
  max-width: 23rem;
}

.vs-table--header {
  display: flex;
  flex-wrap: wrap;
  margin-left: 1.5rem;
  margin-right: 1.5rem;

> span {
  display: flex;
  flex-grow: 1;
}

.vs-table--search {
  padding-top: 0;

.vs-table--search-input {
  padding: 0.9rem 2.5rem;
  font-size: 1rem;

& + i {
    left: 1rem;
  }

&:focus + i {
   left: 1rem;
 }
}
}
}

.vs-table {
  border-collapse: separate;
  border-spacing: 0 1.3rem;
  padding: 0 1rem;

tr {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

td {
  padding: 20px;

&:first-child {
   border-top-left-radius: .5rem;
   border-bottom-left-radius: .5rem;
 }

&:last-child {
   border-top-right-radius: .5rem;
   border-bottom-right-radius: .5rem;
 }
}

td.td-check {
  padding: 20px !important;
}
}
}

.vs-table--thead {
th {
  padding-top: 0;
  padding-bottom: 0;

.vs-table-text {
  text-transform: uppercase;
  font-weight: 600;
}
}

th.td-check {
  padding: 0 15px !important;
}

tr {
  background: none;
  box-shadow: none;
}
}

.vs-table--pagination {
  justify-content: center;
}
}
}
</style>
